import React from 'react';

const Call: React.FC = () => {
  React.useEffect(() => {
    window.location.replace('https://calendly.com/cemtu/intro');
  }, []);
  return <></>;
};

export default Call;
